
// Generated by common/web/keyman-version/build.sh
//
// Note:  does not use the 'default' keyword so that the export name is
// correct when converted to a CommonJS module with `esbuild`.
export class KEYMAN_VERSION {
  static readonly VERSION = "18.0.226";
  static readonly VERSION_RELEASE ="18.0";
  static readonly VERSION_MAJOR = "18";
  static readonly VERSION_MINOR = "0";
  static readonly VERSION_PATCH = "226";
  static readonly TIER ="beta";
  static readonly VERSION_TAG = "-beta";
  static readonly VERSION_WITH_TAG = "18.0.226-beta";
  static readonly VERSION_ENVIRONMENT = "beta";
  static readonly VERSION_GIT_TAG = "release@18.0.226-beta";
}

// Also provides it as a 'default' export.
export default KEYMAN_VERSION;
  
